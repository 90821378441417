import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Collapse,
  Button,
  CardActions,
} from "@mui/material";
import {
  CastForEducation,
  CheckCircle,
  LiveTv,
  Movie,
  Smartphone,
  Speed,
  Tv,
  Wifi,
} from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";
import { useGlobalContext } from "../../context/GlobalContext";
import { createTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { ThemeProvider } from "@mui/system";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useWindowDimensions from "../../Utils/resizeWindow";
import "./Planos.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";

const Planos = (props) => {
  const { city } = useGlobalContext();
  const isMobile = useMediaQuery({ query: `(max-width: 800px)` });
  const isWideDesktop = useMediaQuery({ query: `(max-width: 1800px)` });

  const [expanded, setExpanded] = React.useState(false);
  const [isCombos, setIsCombos] = React.useState(false);
  const { width, height } = useWindowDimensions();

  const mTheme = createTheme({
    palette: {
      ittv: {
        main: "#4f0396",
        contrastText: "#fff",
      },
    },
  });

  const cssCardAttributes = {
    margin: "0 6px 0 0",
    opacity: "30%",
    color: "blue",
    transition: "color 300ms ease-in",
  };

  const handleCityFeats = (icon, transparent) => {
    if (icon === "wifi" && !transparent) {
      return <Wifi className="cardLogo" />;
    }
    if (icon === "wifi" && transparent) {
      return <Wifi className="cardLogo" style={{ color: "transparent" }} />;
    }
  };

  const handlePlanoClick = (plano) => {
    //navigate("/checkout", {state: {plano: plano}});
;
    let contactMessage =
      "Olá, vim pelo site Itnet Telecom e gostaria de saber mais sobre o plano de ";
    let encodedURI = encodeURI(contactMessage);
    window.open(    
      "https://api.whatsapp.com/send?phone=55" +
      city.whatsapp
        .replace(" ", "")
        .replace("-", "")
        .replace("(", "")
        .replace(")", "") +
      "&text=" +
      encodedURI,
      (plano.bandwidth < 1000
        ? plano.bandwidth + "MB!"
        : plano.bandwidth / 1000 + "GB!"));
  };

  let element = [];

  city.planos.forEach((item) => {
    element.push(
      <Card key={item.label} id="cardPlano">
        <CardContent sx={{ padding: "16px" }}>
          <div id="planosContent">
            <span id="planosBandwdth">
              {+item.bandwidth < 1000
                ? item.bandwidth + " MB"
                : item.bandwidth / 1000 + " GB"}
            </span>
            <div id="cardContent">
              <div className="cardItem">
                <div className="cardTitle">
                  <LiveTv className="cardLogo" />
                  <span className="cardSpan">{item.ittv}</span>
                </div>
                <div className="cardSubtitle">
                  <LiveTv
                    className="cardLogo"
                    style={{ color: "transparent" }}
                  />
                  <span className="cardSpan">{item.description}</span>
                </div>
              </div>

              <div className="cardItem">
                <div className="cardTitle">
                  <Movie className="cardLogo" />
                  <span className="cardSpan">Filmes e séries</span>
                </div>
                <div className="cardSubtitle">
                  <Movie
                    className="cardLogo"
                    style={{ color: "transparent" }}
                  />
                  <span className="cardSpan">
                    Acervo com dezenas de filmes e séries exclusivas!
                  </span>
                </div>
              </div>
              {city.feat.map((feature) => (
                <div className="cardItem">
                  <div className="cardTitle">
                    {handleCityFeats(feature.icon, false)}
                    <span className="cardSpan">{feature.label}</span>
                  </div>
                  <div className="cardSubtitle">
                    {handleCityFeats(feature.icon, true)}
                    <span className="cardSpan">{feature.description}</span>
                  </div>
                </div>
              ))}
              <div className="cardItem">
                <div className="cardTitle">
                  <Smartphone className="cardLogo" />
                  <span className="cardSpan">Área do assinante</span>
                </div>
                <div className="cardSubtitle">
                  <Smartphone
                    className="cardLogo"
                    style={{ color: "transparent" }}
                  />
                  <span className="cardSpan">
                    Gerencie faturas, consumo e solicite suporte sem sair de
                    casa!
                  </span>
                </div>
              </div>
              <div className="cardItem">
                <div className="cardTitle">
                  <CheckCircle style={cssCardAttributes} />
                  <span className="cardSpan">Instalação Grátis</span>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
        {/*<CardActions
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {props.isFuncionario ? (
            <span id="spanPrice">R$ {item.price},90</span>
          ) : (
            <ThemeProvider theme={mTheme}>
              <Button
                variant="contained"
                color={isCombos ? "ittv" : "primary"}
                onClick={() => handlePlanoClick(item)}
                startIcon={<WhatsAppIcon />}
              >
                Contratar
              </Button>
            </ThemeProvider>
          )}
          </CardActions>*/}
      </Card>
    );
  });

  return (
    <>
      <div id="planosContainer">
        <div className="planosMsg">
          <span>
            {isCombos
              ? "Conheça nossos combos e turbine sua internet e sua TV!"
              : "Conheça nossos planos e turbine sua internet!"}
          </span>
        </div>
        <Swiper
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 4000 }}
          slidesPerView={width / 300}
          pagination={{
            clickable: true,
            hideOnClick: true,
          }}
          id="cardsContainer"
        >
          {element.reverse().map((item) => (
            <SwiperSlide id="slidePlano">{item}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Planos;
