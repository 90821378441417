import React from 'react';
import { useGlobalContext } from '../../context/GlobalContext';

import './Footer.css'

const FooterEndereco = () => {
    const { city } = useGlobalContext();

    return ( 
        <div className="footerInfoEndereco">
            <div>
                <span>{city.address}</span>
            </div>
            <div>
                <span>{city.razaoSocial}</span>
            </div>
            <div>
                <span>&copy;{ new Date().getFullYear()} - ITNET TELECOM - Todos os direitos reservados</span>
            </div>
            
        </div>
    );
}
 
export default FooterEndereco;